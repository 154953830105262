import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

import {
  PostTitle,
  PostContainer,
  PostImgContainer,
  PostContents,
  PostAuthor,
  PostDate,
  PostSocialsContainer,
} from 'components/BlogComponents'

const Blog = () => {
  const { t } = useTranslation('post3')
  const url = typeof window !== 'undefined' ? window.location.href : null

  return (
    <Layout>
      <Seo title={t('post3Title')} description={t('post3Paragraph1')} />
      <PostContainer>
        <PostTitle>
          <h1>{t('post3Title')}</h1>
        </PostTitle>
        <PostAuthor>
          <p>{t('post3Author')}</p>
        </PostAuthor>
        <PostDate>
          <p>{t('post3Date')}</p>
        </PostDate>
        <PostSocialsContainer>
          <FacebookShareButton
            url={url}
            quote={t('post3Paragraph1')}
            hashtag="#fluentworlds"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </PostSocialsContainer>

        <PostImgContainer>
          <StaticImage
            src="../graphics/thoughtful.jpg"
            alt="Woman in thought"
          />
        </PostImgContainer>

        <PostContents>
          <p>{t('post3Paragraph1')}</p>
          <p>{t('post3Paragraph2')}</p>
          <ul>
            <li>{t('post3ListItem1')}</li>
            <li>{t('post3ListItem2')}</li>
          </ul>
          <p>{t('post3Paragraph3')}</p>
          <h2>{t('post3Subtitle1')}</h2>
          <ul>
            <li>{t('post3ListItem3')}</li>
            <li>{t('post3ListItem4')}</li>
            <li>{t('post3ListItem5')}</li>
            <li>{t('post3ListItem6')}</li>
            <li>{t('post3ListItem7')}</li>
            <li>{t('post3ListItem8')}</li>
            <li>{t('post3ListItem9')}</li>
            <li>{t('post3ListItem10')}</li>
            <li>{t('post3ListItem11')}</li>
            <li>{t('post3ListItem12')}</li>
          </ul>
          <p>{t('post3Paragraph4')}</p>
          <h2>{t('post3Subtitle2')}</h2>
          <ul>
            <li>{t('post3ListItem13')}</li>
            <li>{t('post3ListItem14')}</li>
            <li>{t('post3ListItem15')}</li>
            <li>{t('post3ListItem16')}</li>
            <li>{t('post3ListItem17')}</li>
            <li>{t('post3ListItem18')}</li>
            <li>{t('post3ListItem19')}</li>
            <li>{t('post3ListItem20')}</li>
            <li>{t('post3ListItem21')}</li>
            <li>{t('post3ListItem22')}</li>
          </ul>
          <p>
            {t('post3Paragraph5')}
            <a href="https://youtu.be/qE_NpSQftlY">{t('post3VideoLinkText')}</a>
          </p>
        </PostContents>
      </PostContainer>
    </Layout>
  )
}

export default Blog
